<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <h3>لیست گزارش‌‌ها ناقص نیروهای مسلح</h3>
      </v-card-title>
      <mobile-text v-if="deviceType == 'mobile'" :title="''" />
      <v-card class="cards pa-3" v-else>
        <v-card-text>
          <div>
            <v-row class="time-row">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateFrom">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateFrom"
                    label=" تاریخ از "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="dateFrom"
                  element="dateFrom"
                  color="#00a7b7"
                /> </v-col
              ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateTo">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateTo"
                    label=" تاریخ تا "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="dateTo"
                  element="dateTo"
                  color="#00a7b7"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  class="primary-btn submit-btn"
                  style="float: right"
                  @click="getInsurances()"
                  :disabled="Busy"
                  >اعمال</v-btn
                >
              </v-col>
            </v-row>
            <hr />
            <v-text-field
              v-model="Filter"
              prepend-inner-icon="mdi-magnify"
              label="جستجو"
              single-line
              hide-details
              filled
              rounded
              clearable
              class="text-right w-80 mb-2 search-input"
            ></v-text-field>
            <b-table
              responsive
              show-empty
              :fields="Fields"
              :items="Items"
              empty-text="در بازه زمانی انتخاب شده بیمه‌ای برای نمایش وجود ندارد"
              empty-filtered-text="در بازه زمانی انتخاب شده بیمه‌ای برای نمایش وجود ندارد"
              :filter="Filter"
              :busy="Busy"
              :current-page="CurrentPage"
              :per-page="PerPage"
              @filtered="onFiltered"
              @row-clicked="onRowSelected"
              tbody-tr-class="hover-tr"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell(patientName)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(patientLastName)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(insuranceExpirationDate)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{
                    data.item.insuranceLastSheet
                      ? "تا آخرین صفحه دفترچه"
                      : data.value
                      ? data.value
                      : "-"
                  }}
                </div>
              </template>
              <template v-slot:cell(insurancePageNo)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value ? data.value : "-" }}
                </div>
              </template>
              <template v-slot:cell(insurNo)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value ? data.value : "-" }}
                </div>
              </template>
              <template v-slot:cell(date)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value ? data.value : "-" }}
                </div>
              </template>
              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
              <div slot="table-busy" class="text-center primary--text my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(TotalRows / PerPage)"
              :total-visible="5"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
            ></v-pagination>
            <v-select
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="PerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
            ></v-select>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="insuranceItemDialog" width="600">
      <v-card class="pa-3" v-if="!edited">
        <v-card-title class="mb-2">
          <h4>جزییات</h4>
          <v-spacer></v-spacer>
          <v-icon
            color="red"
            class="mb-2 ms-2 close-icon"
            @click="
              insuranceItemDialog = false;
              edited = false;
            "
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-row class="mt-3">
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="text-right">
              <span class="h2 primary--text">نام بیمار:</span>
              <span class="h2">
                {{ selected.patientName ? selected.patientName : "-" }}</span
              >
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="text-right">
              <span class="h2 primary--text"> نام‌خانوادگی بیمار:</span>
              <span class="h2">
                {{
                  selected.patientLastName ? selected.patientLastName : "-"
                }}</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right" cols="12" sm="6" md="6" lg="6" xl="6">
              <span class="h2 primary--text">شماره بیمه:</span>
              <span class="h2">
                {{ selected.insurNo ? selected.insurNo : "-" }}</span
              >
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="text-right">
              <span class="h2 primary--text"> تاریخ انقضا دفترچه:</span>
              <span class="h2">
                {{
                  selected.insuranceLastSheet
                    ? "تا آخرین صفحه دفترچه"
                    : selected.insuranceExpirationDate
                    ? selected.insuranceExpirationDate
                    : "-"
                }}</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right">
              <span class="h2 primary--text">شماره صفحه دفترچه:</span>
              <span class="h2">
                {{ selected.insurancePageNo ? selected.insurancePageNo : "-" }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-btn @click="edited = true" class="secondary-btn mb-4 me-5 pa-5">
          <v-icon left>edit</v-icon>
          ویرایش</v-btn
        >
      </v-card>
      <v-card v-else style="overflow-x: hidden">
        <v-card-title class="mb-2">
          <h4>ویرایش اطلاعات</h4>
          <br />
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <div class="mt-1">
                <h6 class="red--text float-start">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  امکان خالی کردن فیلد‌هایی که از پیش پر شده‌اند وجود ندارد!
                </h6>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-3 time-row">
            <v-col>
              <v-text-field
                v-model="selectedEdit.patientName"
                label="نام بیمار"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="selectedEdit.patientLastName"
                label="نام‌خانوادگی بیمار"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <v-text-field
                v-model="selectedEdit.insurNo"
                label="شماره بیمه"
                outlined
                dense
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col class="pt-0">
              <v-checkbox
                v-model="selectedEdit.insuranceLastSheet"
                color="primary"
              >
                <template v-slot:label>
                  <span class="mt-2">تا آخرین صفحه دفترچه</span>
                </template>
              </v-checkbox> </v-col
            ><v-col>
              <v-text-field
                outlined
                dense
                type="text"
                v-model="selectedEdit.insuranceExpirationDate"
                label="تاریخ انقضا دفترچه"
                :editable="true"
                class="date-input"
                :disabled="selectedEdit.insuranceLastSheet"
              >
                <template #append>
                  <span id="insuranceExpirationDate">
                    <v-icon class="calender-icon">mdi-calendar-blank</v-icon>
                  </span>
                </template>
              </v-text-field>

              <date-picker
                v-model="selectedEdit.insuranceExpirationDate"
                element="insuranceExpirationDate"
                color="#00a7b7"
                :disabled="selectedEdit.insuranceLastSheet"
              />
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col class="pt-0">
              <v-text-field
                v-model="selectedEdit.insurancePageNo"
                label="شماره صفحه دفترچه"
                outlined
                dense
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
            @click="editInsuranceItem()"
            :disabled="editDisabled"
            class="primary-btn mt-4 mx-2"
            >ثبت</v-btn
          >
          <v-btn @click="edited = false" class="red-btn mt-4">لغو</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
const MobileText = () => import("@/components/MobileText");
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    MobileText,
  },
  data() {
    return {
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      Filter: "",
      Busy: false,
      Fields: [
        { key: "index", label: "#" },
        { key: "patientName", label: "نام بیمار" },
        { key: "patientLastName", label: "نام‌خانوادگی بیمار" },
        { key: "insurNo", label: "شماره بیمه" },
        { key: "insuranceExpirationDate", label: "تاریخ انقضا دفترچه" },
        { key: "insurancePageNo", label: "شماره صفحه دفترچه" },
        { key: "date", label: "تاریخ و ساعت" },
      ],
      Items: [],
      role: "",
      dateFrom: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00",
      timeTo: "23:59",
      busyDocPercent: false,
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      edited: false,
      insuranceItemDialog: false,
      selected: {},
      selectedEdit: {},
    };
  },
  computed: {
    editDisabled() {
      let edited = [];
      let main = [];
      let result = [];

      Object.keys(this.selectedEdit).forEach((key2) => {
        edited.push(this.selectedEdit[key2]);
      });

      Object.keys(this.selected).forEach((key) => {
        main.push(this.selected[key]);
      });

      main.forEach((key, index) => {
        if (key.length > 0 && edited[index].length == 0) {
          result.push(true);
        } else {
          result.push(false);
        }
      });

      if (result.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getInsurances() {
      //NOTE getting services info by start date and end date when page mount
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/insurances/exports/militaryDefetcs",
          {
            startDate: this.dateFrom,
            endDate: this.dateTo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.Items = res.data;
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    editInsuranceItem() {
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/insurances/exports/editMilitary",
          {
            visitId: this.selected.id,
            patientLastName: this.selectedEdit.patientLastName,
            patientName: this.selectedEdit.patientName,
            insuranceExpirationDate: this.selectedEdit.insuranceLastSheet
              ? ""
              : this.selectedEdit.insuranceExpirationDate,
            insuranceLastSheet: this.selectedEdit.insuranceLastSheet,
            insurancePageNo: this.selectedEdit.insurancePageNo,
            insurNo: this.selectedEdit.insurNo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.vLoading = false;
            this.toast(res.data, "success");
            this.edited = false;
            this.insuranceItemDialog = false;
            this.selectedEdit = [];
            this.getInsurances();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
            this.edited = false;
            this.selectedEdit = [];
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
          this.edited = false;
        });
    },
    onRowSelected(item) {
      this.selected = JSON.parse(JSON.stringify(item));
      this.selectedEdit = JSON.parse(JSON.stringify(item));
      this.edited = false;
      this.insuranceItemDialog = true;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },

  mounted() {
    this.role = localStorage.getItem("role");
    this.getInsurances();
  },
};
</script>
